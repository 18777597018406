.tutorial {
    height: 80% !important;
    width: max(200px, 75%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    background-color: transparent;
    padding: 0 20px;
    padding-top: 20px;
}

.tutorial h2 {
    font-size: 25px;
    margin-bottom: 20px;    
    color: #2bcb9a;
    text-decoration: solid 2px #2bcb9a !important;
}

.tutorial p {
    margin-bottom: 20px;
    text-align: center;
    text-align: left;
}

.tutorial-content {
    width: 90%;
    height: 125px;
    overflow-y: auto;
    margin-bottom: 5px;
    padding: 0px;
    font-size: 12px;
    padding-right: 10px;
}

.tutorial-image {
    background: url("/public/images/finger-math.webp") no-repeat;
    background-size: 100% 100%;
    height: 47.5vw;
    width: 57vw;
    border: 1px solid #000000;
}

@media screen and (min-width: 576px) {
    .tutorial {
        width: 50%;
    }
    .tutorial-content {
        padding-right: 15px;
        font-size: 14px;
        width: 100%;
        height: 175px
    }

    .tutorial-image {
        height: 40vw;
        width: 45vw;
    }

}

@media screen and (min-width: 768px) {
    .tutorial {
        width: 55%;
        justify-content: center;
        padding-top: 0;
    }
    
    .tutorial-content {
        width: 45vw;
        padding-right: 15px;
    }

    .tutorial-image {
        height: 36vh;
        width: 45vh;
    }

}

@media screen and (min-width: 992px) {
    .tutorial{
        width: 55vw;
        padding: 10px 30px;
    }
    .tutorial-content {
        width: 48vw;
        height: 120px;
        padding-right: 15px;
        font-size: 16px;
    }

    .tutorial-image {
        height: 40vw;
        width: 100%;
    }

}

@media screen and (min-width: 1200px) {
    .tutorial {
        width: 650px;
        padding: 20px 50px;
    }

    .tutorial-content {
        width: 100%;
        height: 100px;
        padding-right: 15px;
        font-size: 16px;
    }

    .tutorial-image {
        height: 450px;
        width: 100%;
    }

}
