.set-name {
    height: 85%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

form {
    width: min(70%, 400px);
    padding: 10px 20px 25px; 
    background-color: transparent;
}

.set-name label {
    font-size: 20px;
}

.input-name {
    border: 1px solid gray;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
    background: linear-gradient(to right top, #da22ff, #9733ee);
    display: flex;
    flex-wrap: nowrap;
}

.input-name input {
    height: 40px;
    width: 80%;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid gray;
    padding-left: 5px;
    outline: none;
    margin-right: 15px;
    font-family: "Mochiy Pop One", sans-serif;
    padding-bottom: 0;
    border-radius: 5px;
}

.input-name button {
    background-color: black;
    color: wheat;
    font-size: 12px;
    height: 40px;
    width: auto;
    white-space: nowrap;
    padding: 0 5px;
    border-radius: 5px;
    border: none;
    font-family: "Mochiy Pop One", sans-serif;
    outline: none;
}

@media screen and (min-width: 576px){
    form {
        width: 400px;
    }

    .input-name button,
    .input-name input {
        font-size: 16px;
    }

}