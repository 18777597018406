* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: "Mochiy Pop One";
    src: url(/public/fonts/Mochiy-Pop_One/MochiyPopOne-Regular.woff);
    font-display: swap;
}

.html {
    font-size: 12px;
}
      
body{
    background: url("/public/images/background.webp") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    font-family: "Mochiy Pop One", sans-serif;
    font-style: normal;
    font-weight: normal;
    height: 100vh;
    z-index: 10;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 5vh;
    width: 19vh;
    background-image: url("/public/images/logo.webp");
    background-size: 100% 100%;
    position: static;
}

.frame {
    position: static;
    height: 7vh;
    width: 20vh;
    background-image: url("/public/images/frame.webp");
    background-size: 100% 100%;
}

.frameHide {
    opacity: 0;
    height: 7vh;
    width: 20vh;
}

.button-main {
    width: 200px;
    height: 7vh;
    border-radius: 10px;
    border: none;
    background-image: url("/public/images/bg.webp");
    background-size: 100% 100%;
    font-size: max(1.5vh, 1.5vw);
    font-weight: bold;
    font-family: "Mochiy Pop One", sans-serif;
    cursor: pointer;
    margin: 0 auto;
    color: black;
    margin-bottom: 5vh;
    letter-spacing: 3px;
}

.button-main:hover {
    color: #ef3349;
}

.hide {
    opacity: 0;
}

button:hover {
    cursor: pointer;
    outline: none;
}

ul, li {
    list-style: none;   
}

a {
    color: black;
    text-decoration: none;
}
