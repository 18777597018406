#menu, .menu {
    height: 5vh;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.nav-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}


.nav-link>li:not(:last-child) {
    margin-right: 20px;
    transition: all 0.4 ease-in;
}

.nav-link li:hover {
    border-bottom: 3px solid green;
    cursor: pointer;
}

.nav-link li:hover a {
    color: green;
}

@media screen and (min-width: 576px) {
    #menu li {
        font-size: 20px;
    }
}
