.medal {
    width: 6rem;
    height: 6rem;
    z-index: 1000;
    background-size: 100% 100%;
    margin: 0 auto;
    display: none;
}

.medal1 {
    display: block;
    background-image: url("/public/images/gold.webp");
}

.medal2 {
    display: block;
    background-image: url("/public/images/silver.webp");
}

.medal3 {
    display: block;
    background-image: url("/public/images/bronze.webp");
}

.medal4 {
    display: block !important;
}

.note {
    font-size: 2vh;
    text-align: center;
    padding: 20px;
    display: none;
}

.award {
    height: 85%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.award h6 {
    font-size: 16px;
    text-align: center;
    margin: 0;
    letter-spacing: 3px;
}

.award div {
    width: min(30vw, 30vh);
    height: min(30vw, 30vh);
}

h6.score {
    line-height: 1.5;
}

@media screen and (min-width: 576px){
    .award h6 {
        font-size: 20px;
    }
    
}

@media screen and (min-width: 1200px){
    .award h6 {
        font-size: 40px;
    }
    
}
