#game-mode {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#game-mode .logo {
  margin-bottom: 4vh;
}

.box-mode {
  height: 70%;
  width: 70vw;
}

.box-mode h2 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 5%;
  margin-top: 5vh;
}

.mode > div {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.mode > div:not(:last-child) {
  margin-bottom: 1vh;
}

.mode label {
  font-size: min(3vh, 3vw);
}

.box-mode select {
  padding-left: 0.5rem;
  height: 5vh;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 0px 3px 4px gray;
  font-size: min(3vh, 3vw);
  width: 60%;
  background-color: aquamarine;
  font-family: "Mochiy Pop One", sans-serif;
}

/* Responsive */
@media screen and (min-width: 576px) {
  .example {
    background: url("/public/images/example_2.gif") no-repeat;
    background-size: 100% 100%;
    width: 42vh;
  }

  .box-mode h2 {
    font-size: 25px;
  }
  
}
@media screen and (min-width: 768px) {
  .mode {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2vh 8vw;
    width: 70vw;
  }

  .box-mode {
    font-size: max(2vw, 2vh);
  }

  .box-mode label {
    font-size: max(1.25vw, 1.25vh);
  }

  .box-mode select {
    font-size: max(1.25vw, 1.25vh);
    height: 8vh;
  }

  .box-mode h2 {
    font-size: 30px;
  } 

}

@media screen and (min-width: 1200px) {
  .mode {
    display: block;
    width: 50vw;
    margin: auto;
  }

  .mode > div {
    width: 100%;
    overflow: hidden;
  }

  .mode > div:not(:last-child) {
    margin-bottom: 2vh;
  }

  .mode label {
    font-size: min(3vh, 3vw);
  }

  .box-mode select {
    padding-left: 0.5rem;
    height: 5vh;
    border: 2px solid gray;
    border-radius: 5px;
    box-shadow: 0px 3px 4px gray;
    font-size: min(3vh, 3vw);
    width: 60%;
    background-color: aquamarine;
    font-family: "Mochiy Pop One", sans-serif;
  }

  .box-mode h2 {
    font-size: 45px;
  }
  
}
