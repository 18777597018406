.box-calculator {
  letter-spacing: 2px;
  height: 100%;
  font-size: 2.25vh;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-calculator .calculator-main {
  background-color: transparent;
  width: 100%;
  padding: 15px;
}

.box-handle {
  width: min(50vh, 50vw);
  margin: auto;
}

.result {
  margin-bottom: 1.5vh;
}

.input {
  margin-bottom: 2vh;
  font-size: 2vh;
}

.answer {
  color: #2bcb9a;
  width: 80%;
  border-bottom: solid 3px #cbe2d7 !important;
  padding-bottom: 10px;
  position: relative;
  left: 10%;
  font-size: 2.5vh;
  text-align: center;
}

.answer span {
  font-size: 1vh;
  padding: 5px;
  background-color: #cbe2d7;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 0;
}

.row {
  padding: 0 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin-bottom: 1vh;
}

.row .score,
.row .time {
  font-size: calc(1.75 * min(1vh, 1vw));
  margin-top: 2vh;
}

.button-panel {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: min(2.5vw, 2.5vh);
  margin-bottom: 4vh;
}

.button-panel button {
  width: min(13vh, 13vw);
  height: min(13vh, 13vw);
  font-size: min(5vh, 5vw);
  border-radius: 20%;
  border: none;
  box-shadow: 0px 0px 2px gray;
  background-color: #f3f3f3;
  color: #ef3349;
  font-weight: bold;
  font-family: "Mochiy Pop One", sans-serif;
  border-radius: 35%;
}

.button-panel button:hover {
  color: #f3f3f3;
  background-color: #ef3349;
}

.row-button {
  justify-content: space-between;
}

.row-button button {
  width: 45%;
  height: 6vh;
  background: url("/public/images/bg.webp") no-repeat;
  background-size: 100% 100%;
  border: none;
  font-size: 1.75vh;
  font-family: "Mochiy Pop One", sans-serif;
  font-weight: bold;
  color: black;
  letter-spacing: 2px;
}

.row-button button:hover {
  color: #ef3349;
}

.overlay {
  opacity: 0.3;
  z-index: 10;
}

.no-overlay {
  opacity: 1;
}

.Toastify__toast-container {
  top: 0 !important;
  height: 0 !important;
}

.Toastify__toast {
  position: absolute;
  top: 35vh;
  height: 30vh;
  width: 100vw;
  box-shadow: 0px 0px 2px gray;
  opacity: 1 !important;
  z-index: 100000;
}

.button-close {
  position: absolute;
  top: 2vh;
  right: 2vh;
  scale: 2;
  border-radius: 20%;
  font-weight: 100;
  padding: 0.5vh 1vh;
}

.box-game {
  z-index: 10;
  height: 90%;
}

.button-close:hover {
  border: 1px solid gray;
  background-color: #ef3349;
  cursor: pointer;
}

.Toastify__toast-icon {
  display: none !important;
  width: 0 !important;
}

.custom-toast {
  font-family: "Mochiy Pop One", sans-serif;
  letter-spacing: 2px;
  width: 100%;
  height: 100%;
  font-size: 2vh;
  font-weight: bold;
  color: black;
}

.custom-toast h6 {
  font-size: min(3vh, 3vw);
  text-align: center;
  margin-bottom: 3vh;
}

.custom-toast p {
  font-size: min(2vh, 2vw);
  text-align: center;
  margin-bottom: 3vh;
}

.custom-toast span {
  letter-spacing: 2px;
  font-size: min(2vh, 2vw);
  white-space: nowrap;
  font-weight: 100;
  display: block;
  margin-bottom: 3vh;
  text-align: center;
}

.button-confirm {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin: auto;
}

.custom-toast button {
  font-family: "Mochiy Pop One", sans-serif;
  font-size: 1vh;
  background-color: #ffcf25;
  height: 5vh;
  width: 35%;
  font-weight: 200;
  border: none;
  border-radius: 5%;
  color: #f3f3f3;
}

.custom-toast button.button-no {
  width: 50%;
  background-color: #ef3349;
}

.custom-toast button.button-no:hover {
  background-color: rgb(73, 46, 46);
  color: #f3f3f3;
}

.custom-toast button.button-yes:hover {
  background-color: green;
  color: #f3f3f3;
}

.finger {
  position: absolute;
  left: 50%;
  bottom: 1vh;
  transform: translateX(-50%);
  width: min(50vh, 50vw);
  height: min(10vh, 10vw);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finger > div {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
}

.fingerLeft_0 {
  background-image: url("/public/images/number_0.webp");
  background-size: 100% 100%;
}

.fingerLeft_10 {
  background-image: url("/public/images/number_10.webp");
  background-size: 100% 100%;
}

.fingerLeft_20 {
  background-image: url("/public/images/number_20.webp");
  background-size: 100% 100%;
}

.fingerLeft_30 {
  background-image: url("/public/images/number_30.webp");
  background-size: 100% 100%;
}

.fingerLeft_40 {
  background-image: url("/public/images/number_40.webp");
  background-size: 100% 100%;
}

.fingerLeft_50 {
  background-image: url("/public/images/number_50.webp");
  background-size: 100% 100%;
}

.fingerLeft_60 {
  background-image: url("/public/images/number_60.webp");
  background-size: 100% 100%;
}

.fingerLeft_70 {
  background-image: url("/public/images/number_70.webp");
  background-size: 100% 100%;
}

.fingerLeft_80 {
  background-image: url("/public/images/number_80.webp");
  background-size: 100% 100%;
}

.fingerLeft_90 {
  background-image: url("/public/images/number_90.webp");
  background-size: 100% 100%;
}

.fingerRight_0 {
  background-image: url("/public/images/number_0.webp");
  background-size: 100% 100%;
}

.fingerRight_1 {
  background-image: url("/public/images/number_1.webp");
  background-size: 100% 100%;
}

.fingerRight_2 {
  background-image: url("/public/images/number_2.webp");
  background-size: 100% 100%;
}

.fingerRight_3 {
  background-image: url("/public/images/number_3.webp");
  background-size: 100% 100%;
}

.fingerRight_4 {
  background-image: url("/public/images/number_4.webp");
  background-size: 100% 100%;
}

.fingerRight_5 {
  background-image: url("/public/images/number_5.webp");
  background-size: 100% 100%;
}

.fingerRight_6 {
  background-image: url("/public/images/number_6.webp");
  background-size: 100% 100%;
}

.fingerRight_7 {
  background-image: url("/public/images/number_7.webp");
  background-size: 100% 100%;
}

.fingerRight_8 {
  background-image: url("/public/images/number_8.webp");
  background-size: 100% 100%;
}

.fingerRight_9 {
  background-image: url("/public/images/number_9.webp");
  background-size: 100% 100%;
}

.fingerBase {
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  border: 1px solid brown;
}

/* Responsive */
@media screen and (min-width: 480px) {
  .Toastify__toast-container {
    margin: auto !important;

    height: auto !important;
    width: max-content !important;
    border-radius: 10px !important;
  }

}

@media screen and (min-width: 576px) {
  .Toastify__toast-container {
    margin: auto !important;
    top: 0vh !important;
    height: 0px !important;
    border-radius: 10px !important;
  }

  .box-calculator .calculator-main {
      width: 300px;
  }

  .Toastify__toast {
    height: 32vh;
    width: 64vh;
    box-shadow: 0px 0px 2px gray;
  }

  .button-close {
    position: absolute;
    top: 2vh;
    right: 2vh;
    scale: 1.5;
    border-radius: 20%;
    font-weight: 100;
    padding: 0.5vh 1vh;
  }

  .button-confirm button {
    font-size: min(2vh, 2vw);
  }

  .custom-toast span {
    font-size: min(2vh, 2vw);
  }

  .box-calculator {
    font-size: 3.5vh;
  }

  .box-handle {
    width: min(35vw, 35vh);
  }

  .button-panel {
    gap: min(2.5vw, 2.5vh);
  }

  .button-panel button {
    width: min(7vw, 7vh);
    height: min(7vw, 7vh);
    font-size: 2.5vh;
  }

  .finger {
    width: min(50vh, 50vw);
    height: min(10vh, 10vw);
  }

  .answer {
    width: 60%;
    left: 20%;
  }

  .row {
    padding: 0 0.5vw;
    margin-bottom: 1.56vh;
  }

  .input,
  .answer {
    font-size: 3vh;
  }

  span.player-name {
    font-size: 25px;
  }

}

@media screen and (min-width: 768px) {
  .row-button button {
    height: 6vh;
    font-size: 2vh;
  }

  .box-calculator .calculator-main {
      width: 350px;
  }

  .box-handle {
    width: min(35vw, 35vh);
  }

  .answer {
    width: 50%;
    left: 25%;
  }

  .button-panel button {
    height: min(8vw, 8vh);
    width: min(8vw, 8vh);
    font-size: 3vh;
  }

  .row {
    padding: 0, min(0.25vw, 0.25vh);
  }

}

@media screen and (min-width: 992px) {
  .box-handle {
    width: min(32vw, 32vh) !important;
  }

  .box-calculator .calculator-main {
    width: 400px;
  }

  .box-calculator {
    font-size: 3vh;
  }

  .row-button button {
    height: 6vh;
    font-size: 2.5vh;
  }

  .row {
    padding: 0 0.5vw;
  }

  .row .score,
  .row .time {
    font-size: min(1.5vh, 1.5vw);
  }

  .button-panel button {
    height: min(7.5vw, 7.5vh);
    width: min(7.5vw, 7.5vh);
    font-size: min(2.75vw, 2.75vh);
  }

  .count {
    font-size: 25px;
  } 

}

@media screen and (min-width: 1200px) {
  .answer {
    width: 40%;
    left: 30%;
  }

  .box-calculator {
    height: 88%;
    font-size: 4vh;
  }

  .box-handle {
    width: 50vh;
  }

  .button-panel button {
    height: 8vh !important;
    width: 8vh;
    font-size: 3.5vh;
    height: 7.5vh;
  }

}
