.top-players {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-players .example {
  background-image: url(../../public/images/example_1.gif);
  background-size: 100% 100%;
  border: 2px solid green;
  width: 65vw;
  height: 65vw;
}

@media screen and (min-width: 576px) {
  .top-players .example {
    background-image: url(../../public/images/example_1.gif);
    background-size: 100% 100%;
    width: 55vw;
    height: 55vw;
  }

}

@media screen and (min-width: 768px) {
  .top-players .example {
    background-image: url(../../public/images/example_2.gif);
    background-size: 100% 100%;
    width: 61.25vw;
    height: 35vw;
  }
  
}

@media screen and (min-width: 992px) {
  .top-players .example {
    background-image: url(../../public/images/example_2.gif);
    background-size: 100% 100%;
    width: 700px;
    height: 400px;
  }

}
